<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav
      v-if="user"
      class="nav align-items-center ml-auto"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.nombre }}
            </p>
            <span class="user-status">{{ user.tipoUsuario }}</span>
          </div>

          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.imagen"
            class="badge-minimal"
            badge-variant="success"
            :text="avatarText(user.nombre)"
            :title="avatarText(user.nombre)"
          />
        </template>
        <b-dropdown-item
          :to="{ name: 'configuraciones' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Configuración</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.prevent="signOut"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Cerrar Sesión</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BDropdownDivider,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapGetters, mapActions } from 'vuex'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
      signOutAction: 'auth/signOut',
    }),
    signOut() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Cerrando Sesión...',
          icon: 'RefreshCcwIcon',
          variant: 'primary',
          text: `Hasta pronto ${this.user.nombre}! 👋 `,
        },
      },
      {
        timeout: 3000,
      })
      this.signOutAction().then(() => {
        this.$router.replace({
          name: 'login',
        })
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>
